//import { axios } from 'vue/types/umd';
import { apiURL } from '@/main'
import { app } from '@/main'
import { showError } from '@/services/axios'
import { defineStore } from 'pinia'
export const useSampleStatusStore = defineStore('sampleStatusStore', {
  state: () => ({
    sampleStatus: [] as any[]
  }),
  actions: {
    async fetchSampleStatuses() {
      if (this.sampleStatus.length == 0) {
        try {
          const result = await app.axios.get(apiURL + '/sampleStatuses')
          this.sampleStatus = result.data
        } catch (e) {
          showError(e)
        }
      }
    }
  }
})
